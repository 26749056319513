import * as React from 'react';
import Layout from '@components/layouts/Layout';
import AuthorSection from '@components/AuthorSection';

const AboutPage = ({ children, ...props }) => {
  return (
    <Layout pageTitle="About" {...props}>
      <AuthorSection />
    </Layout>
  );
};
export default AboutPage;
