import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useNavigationPopups } from '../contexts/MainAppContext';

const AuthorSection = (props) => {
  const { showSignupPopup } = useNavigationPopups();
  return (
    <div className="bg-author xl:pt-40 pt-5">
      <section id="author" className="relative w-screen xl:px-20 xl:-mt-24 xl:gap-x-10 xl:pt-36 pt-0 xl:pb-28 pb-28">
        <div className="w-full max-w-screen-xl mx-auto relative">
          <div className="xl:text-right text-center xl:mw-55 xl:absolute relative author-img xl:float-right xl:inline mx-auto">
            <StaticImage
              src={'../images/home-kiran-bhat-img.jpg'}
              formats={['auto', 'webp', 'avif']}
              alt=""
              className="mx-auto w-100"
              placeholder="blurred"
            />
          </div>
        </div>
        <div className="px-5 max-w-screen-2xl mx-auto relative">
          <div className="text-center relative w-100 xl:mw-55 mw-100 xl:mt-0 -mt-20 mdl:text-left author-box bg-green xl:p-[50px] p-10 rounded-xl">
            <div className="xl:text-left text-left" id="meet-the-author">
              <h3 className="xl:text-2xl text-2xl font-rokkit font-bold uppercase text-white">Kiran Bhat</h3>
              <p className="mt-2 font-opensans text-white tracking-2px text-[12px]">MEET THE AUTHOR</p>
              <p className="xl:mt-10 mt-5 font-opensans text-white">
                I'm an avid world traveller. I've now been to about 134 countries, and I've lived in 19 places across the planet. Why have I
                spent over ten years never living in one country, and not for longer than a few months? Because I'm dedicated to my
                principles. I believe that if you want to write something truly representative of a foreign culture, mentality, or
                lifestyle, you really have to live that life. When I was trying to capture tribal life in We of the forsaken world..., I
                stayed with an indigenous community in Manu Jungle in Peru, sleeping in their hammocks, eating alongside them fruits and
                vegetables from the jungle. In that same way, if I wanted to set a story in a random part of the world, I would want it to
                be a place I have at the very least visited, interacted with people, or observed.
              </p>
              <p className="xl:mt-10 mt-5 font-opensans text-white">
                The coronavirus pandemic has changed a lot of the way we live. I'm lucky to have been able to travel before it all occurred.
                Not every story of Girar is set in a country or region or city I've visited. For such places, I tried really hard to use the
                Internet to get in touch with locals, using websites like Goodreads and Couchsurfing to find beta-readers. I also did my
                best to research what I could.
              </p>
              <p className="xl:my-10 my-5 font-opensans text-white">
                Nonetheless, a lot of myself has gone into Girar. These stories don't necessarily represent my family, nor do they fully
                represent the countries they are set in, but in merging my love for my parents with my love for the world, I truly believe I
                have created something worth reading.
              </p>
              <button
                className="btn-sm mt-10 block mx-auto button-white xl:w-1/3 xl:p-3 py-3 px-4 transition-all duration-300"
                onClick={(ev) => {
                  showSignupPopup();
                }}>
                Get Started
              </button>
            </div>
          </div>
        </div>

        <div className="px-5 max-w-screen-2xl mx-auto relative">
          <div className="col-span-12 mw-780 mx-auto bg-black px-10 py-20 xl:mt-20 mt-5 rounded-xl">
            <div className="xl:my-10 my-5">
              <h2 className="font-rokkit xl:text-7xl text-3xl font-semibold text-center uppercase text-white ">SUPPORT THE AUTHOR</h2>
              <p className="font-opensans text-sm text-white text-center uppercase tracking-2px xl:mb-8 my-5">
                Your gift of support will serve as an investment <br /> to the future of literature
              </p>
              <div className="grid grid-cols-12">
                {/*
                <div className="xl:col-span-4 col-span-12">
                  <a href="https://www.patreon.com/user/creators?u=29451737" target="_blank" rel="noreferrer">
                    <button className="uppercase block xl:mb-6 mb-0 mx-auto xl:mt-0 mt-5 xl:px-5 py-3 xl:w-auto w-full text-orange-150 border-4 border-orange-150 rounded-md bottom-10 shadow-inner2 font-bold font-rokkit text-xl bg-armadillo hover:bg-white-rock hover:text-brown-50 transition-all duration-500">
                      Become a patron
                    </button>
                  </a>
                </div>
                */}
                <div className="col-span-12 mx-auto w-100">
                  <a
                    className="btn-sm block mx-auto button-white xl:w-1/3 xl:p-3 py-3 px-4"
                    href="https://www.paypal.com/donate/?hosted_button_id=PZULDYZ7EJL6J"
                    target="_blank"
                    rel="noreferrer">
                    <button className="bottom-10 font-bold font-opensans w-100 text-center transition-all duration-500">
                      <StaticImage
                        className="mx-1 mt-[4px]"
                        src={'../images/heart-icon.svg'}
                        formats={['auto', 'webp', 'avif']}
                        width={17}
                        alt=""
                        placeholder="blurred"
                      />
                      Donate now
                    </button>
                  </a>
                </div>
                {/*
                <div className="xl:col-span-4 col-span-12">
                  <a href="https://buy.stripe.com/9AQdRhbMH1X6cy47ss" target="_blank" rel="noreferrer">
                    <button className="uppercase block xl:mb-32 mb-0 mx-auto xl:mt-0 mt-5 xl:px-5 py-3 xl:w-auto w-full text-orange-150 border-4 border-orange-150 rounded-md bottom-10 shadow-inner2 font-bold font-rokkit text-xl bg-armadillo hover:bg-white-rock hover:text-brown-50 transition-all duration-500">
                      Donate with Stripe
                    </button>
                  </a>
                </div>
                */}
              </div>
            </div>
          </div>
          {/*
          <div className="flex xl:pb-32 pb-5 mt-12">
            <div className="col-span-12">
              <StaticImage
                src={'../images/home-half-globe.png'}
                formats={['auto', 'webp', 'avif']}
                width={1920}
                alt=""
                className="mx-auto max-w-1920px absolute left-0 right-0 xl:bottom-0 bottom-8"
                placeholder="blurred"
              />
            </div>
          </div>
          */}
        </div>
      </section>
    </div>
  );
};

export default AuthorSection;
